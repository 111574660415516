import React from 'react';
import validator from 'validator';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  }
}));

export default function QuestionsForm({formValues, handleBack, handleNext}) {
  const classes = useStyles();
  
  const emptyQuestion = {
    question: {
      value: "",
      error: false
    }
  }

  const initialState = formValues.questions.length ? formValues.questions.map(question => {
    return {
      question: {
        value: question, 
        error: false
      }
    }
  }) : [emptyQuestion];

  const [questions, setQuestions] = React.useState(initialState);

  const addQuestion= () => {
    setQuestions([
      ...questions,
      emptyQuestion
    ]);
  };

  const removeQuestion= (index) => {
    const updatedQuestions = questions.filter((question, questionIndex) => {
      if (questionIndex !== index) {
        return question;
      }
    });

    setQuestions(updatedQuestions);
  }

  const updateQuestion = (event, name, index, question) => {
    const { value } = event.target;

    const error = value ? false : true;

    const updatedQuestion = {
      ...question,
      [name]: {
        value: value,
        error: error
      }
    };

    const updatedQuestions= questions.map((question, questionIndex) => questionIndex === index ? updatedQuestion : question);

    setQuestions(updatedQuestions);
  }

  const handleSubmitQuestions = () => {
    let hasValidationError = false;

    questions.forEach((question, index) => {
      const questionError = question.question.value ? false : true;
      
      if (questionError) {        
        const updatedQuestion = {
          question: {
            value: question.question.value,
            error: questionError
          }
        };
        
        hasValidationError = true;
        const updatedQuestions = questions.map((question, questionIndex) => questionIndex === index ? updatedQuestion : question);
        setQuestions(updatedQuestions);
      }
    });

    if (!hasValidationError) {
      const surveyQuestions = questions.map(question => question.question.value);
      handleNext({questions: surveyQuestions});
    }
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Add Questions
      </Typography>
      <Typography>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      </Typography>
      <List>
        {questions.map((question, index) => (
          <ListItem key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  error ={question.question.error}
                  helperText={question.question.error ? "Question is invalid" : ""}
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  label="Question"
                  value={question.question.value}
                  autoComplete="question"
                  onChange={event => updateQuestion(event, "question", index, question)}
                />
              </Grid>
            </Grid>
            <IconButton className={classes.margin} onClick={() => removeQuestion(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Button color="primary" onClick={addQuestion}>  
        <AddIcon />
        <Typography variant="body2">Add Question</Typography>
      </Button>
      <div className={classes.buttons}>
        <Button variant="contained" color="secondary" className={classes.button} onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitQuestions}>
          Next
        </Button>
      </div>
    </React.Fragment>
  );
}
