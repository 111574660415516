/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:39f37f08-d68f-4a2b-b157-f74807660e5e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_aYhzx6wgI",
    "aws_user_pools_web_client_id": "4ril31qccp16o4d0v8vf717ts7",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "surveyApi",
            "endpoint": "https://o1z9kawlm3.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
