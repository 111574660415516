import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function Complete() {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Survey Sent
      </Typography>
      <Typography>
        Your survey has been succesfully sent.
      </Typography>
    </React.Fragment>
  );
}