import React from 'react';
import validator from 'validator';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  }
}));

export default function RecipientsForm({formValues, handleNext}) {
  const classes = useStyles();
  
  const emptyRecipient = {
    phoneNumber: {
      value: "",
      error: false
    }
  }

  const initialState = formValues.recipients.length ? formValues.recipients.map(recipient => {
    return {
      phoneNumber: {
        value: recipient, 
        error: false
      }
    }
  }) : [emptyRecipient];

  const [recipients, setRecipients] = React.useState(initialState);

  const addRecipient = () => {
    setRecipients([
      ...recipients,
      emptyRecipient
    ]);
  };

  const removeRecipient = (index) => {
    const updatedRecipients = recipients.filter((recipient, recipientIndex) => {
      if (recipientIndex !== index) {
        return recipient;
      }
    });

    setRecipients(updatedRecipients);
  }

  const updateRecipient = (event, name, index, recipient) => {
    const { value } = event.target;

    const error = value ? !validator.isMobilePhone(value, "en-US", { strictMode: true }) : true;

    const updatedRecipient = {
      ...recipient,
      [name]: {
        value: value,
        error: error
      }
    };

    const updatedRecipients = recipients.map((recipient, recipientIndex) => recipientIndex === index ? updatedRecipient : recipient);

    setRecipients(updatedRecipients);
  }

  const handleSubmitRecipients = () => {
    let hasValidationError = false;

    recipients.forEach((recipient, index) => {
      const phoneNumberError = recipient.phoneNumber.value ? recipient.phoneNumber.error : true;
      
      if (phoneNumberError) {        
        const updatedRecipient = {
          phoneNumber: {
            value: recipient.phoneNumber.value,
            error: phoneNumberError
          }
        };
        
        hasValidationError = true;
        const updatedRecipients = recipients.map((recipient, recipientIndex) => recipientIndex === index ? updatedRecipient : recipient);
        setRecipients(updatedRecipients);
      }
    });

    if (!hasValidationError) {
      const phoneNumbers = Array.from(new Set(recipients.map(recipient => recipient.phoneNumber.value)));
      handleNext({recipients: phoneNumbers});
    }
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Add Recipient
      </Typography>
      <Typography>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      </Typography>
      <List>
        {recipients.map((recipient, index) => (
          <ListItem key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  error ={recipient.phoneNumber.error}
                  helperText={recipient.phoneNumber.error ? "Phone number is invalid" : ""}
                  variant="outlined"
                  required
                  fullWidth
                  label="Phone Number"
                  value={recipient.phoneNumber.value}
                  autoComplete="recipient-phone-number"
                  onChange={event => updateRecipient(event, "phoneNumber", index, recipient)}
                />
              </Grid>
            </Grid>
            <IconButton className={classes.margin} onClick={() => removeRecipient(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Button color="primary" onClick={addRecipient}>  
        <AddIcon />
        <Typography variant="body2">Add Recipient</Typography>
      </Button>
      <div className={classes.buttons}>
        <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitRecipients}>
          Next
        </Button>
      </div>
    </React.Fragment>
  );
}
