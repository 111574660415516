import React from 'react';
import { API } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  }
}));

export default function Review({formValues, handleBack, handleNext}) {
  const classes = useStyles();

  async function postData(recipients, questions) { 
    const apiName = 'surveyApi';
    const path = '/items/send';
    const params = {
        body: {
          recipients: recipients,
          questions: questions
        }
    };

    return await API.post(apiName, path, params);
  }

  const handleSendSurvey = () => {
    console.log(formValues);
    
    if (formValues.recipients.length === 0 || formValues.questions.length == 0) {
      console.log("Unable to send surveys missing questions or recipients");
      return;
    }

    postData(formValues.recipients, formValues.questions);
    handleNext();
  } 

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column">
        <Box flexGrow={1}>
          <Typography variant="h6" gutterBottom>
            Questions
          </Typography>
          <List disablePadding>
            {formValues.questions.map((question) => (
              <ListItem className={classes.listItem}>
                <ListItemText primary={question} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box>
          <Typography variant="h6" gutterBottom>
            Recipients
          </Typography>
          <List disablePadding>
            {formValues.recipients.map((recipient) => (
              <ListItem className={classes.listItem}>
                <ListItemText primary={recipient} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <div className={classes.buttons}>
        <Button variant="contained" color="secondary" className={classes.button} onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" color="primary" className={classes.button} onClick={handleSendSurvey}>
          Send
        </Button>
      </div>
    </React.Fragment>
  );
}