import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import SimpulseSurvey from './SimpulseSurvey';

const SignInTheme = {
  signInButtonIcon: { 'display': 'none' }
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://simpulse.co/">
        Simpulse.co
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function App() {
  return (
    <div className="app">
      <SimpulseSurvey />
      <Copyright />
    </div>
  );
}

export default App;