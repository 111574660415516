import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { AmplifyContainer, AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import './index.css';

Amplify.configure(awsconfig);

ReactDOM.render(
  <React.StrictMode>
    <AmplifyContainer>
      <AmplifyAuthenticator>
        <AmplifySignIn slot="sign-in">
          <div slot="secondary-footer-content"></div>
        </AmplifySignIn>
        <App />
      </AmplifyAuthenticator>  
    </AmplifyContainer>
  </React.StrictMode>,
  document.getElementById('root')
);
